import React from "react"
import Layout from "../components/layout"
import Meta from "../components/meta"
import Hero from "../components/hero"
import Image from "../components/image"

export default () => (
  <Layout>
    <Meta title="ハードリペア" />
    <Hero title="ハードリペア" />
    <div className="max-w-xl mx-auto text-gray-800 mb-8 px-5 text-sm">
      <p className="mb-1">
        オーバーホール作業は専用の特殊ツール(SST)と正確な知識(データー)及び経験による技術(ノウハウ)が備わって完全な製品を作り上げることができます。
      </p>
      <p className="mb-1">
        弊社ではメーカー直系のリビルドセンターにて30年に近い技術取得と40年以上に渡りメルセデスベンツの整備に従事しております。
      </p>
      <p className="mb-1">
        海外からの豊富な部品の調達や入手不可能な部品の国内制作など永年培ってきた豊富なアイデア等でご予算に応じた対応が可能です。
      </p>
      <p className="mb-1">
        新品時と同等または対策等の改善をする事により　それ以上の製品作りを心掛けています。
      </p>
    </div>
    <div className="text-center text-gray-800 mb-8">
      <h3 className="text-xl font-bold mb-1">ATオーバーホール</h3>
      <div className="block md:flex mx-auto justify-center">
        <div className="pr-2 mb-4 text-center">
          <Image filename="at1.png" alt="" className="w-48 mx-auto" />
          <h4>ボルグワーナー　DG150</h4>
        </div>
        <div className="pr-2 mb-4 text-center">
          <Image filename="at2.png" alt="" className="w-48 mx-auto" />
          <h4>E-Type</h4>
        </div>
        <div className="pr-2 mb-4 text-center">
          <Image filename="at3.png" alt="" className="w-48 mx-auto" />
          <h4>722.6（電子制御５速）</h4>
        </div>
        <div className="text-center mb-4">
          <Image filename="at4.png" alt="" className="w-48 mx-auto" />
          <h4>722.9 7ＧEHCU</h4>
        </div>
      </div>
    </div>
    <div className="text-center text-gray-800 mb-8">
      <h3 className="text-xl font-bold mb-1">エンジンオーバーホール</h3>
      <p className="max-w-2xl mx-auto text-gray-800 mb-2 text-sm px-5 text-left">
        出力低下や白煙・黒煙が発生する、異常な音がする、チェックエンジンが点灯するなど正常ではない現象が発生した場合、分解による点検後オーバーホールにて修理が必要な事があります。
      </p>
      <div className="block md:flex mx-auto justify-center">
        <div className="pr-2 mb-4 text-center">
          <Image filename="eg1.png" alt="" className="w-48 mx-auto" />
          <h4>Ｍ112</h4>
        </div>
        <div className="pr-2 mb-4 text-center">
          <Image filename="eg2.png" alt="" className="w-48 mx-auto" />
          <h4>AMG M156</h4>
        </div>
        <div className="pr-2 mb-4 text-center">
          <Image filename="eg3.png" alt="" className="w-48 mx-auto" />
          <h4>Ｍ272</h4>
        </div>
        <div className="text-center mb-4">
          <Image filename="eg4.png" alt="" className="w-48 mx-auto" />
          <h4>M273</h4>
        </div>
      </div>
    </div>
    <div className="text-center text-gray-800 mb-8">
      <h3 className="text-xl font-bold mb-1">ステアリングギアボックス</h3>
      <p className="max-w-2xl mx-auto text-gray-800 mb-2 text-sm px-5 text-left">
        オイル漏れやステアリング操作のふらつき現象などが起こった場合、
        部分的な修理よりオーバーホールによって新品時の状態に保つことができます。
      </p>
      <div className="block md:flex mx-auto justify-center">
        <div className="pr-2 mb-4 text-center">
          <Image filename="sg1.png" alt="" className="w-48 mx-auto" />
          <h4>LS68 ボール循環式</h4>
        </div>
        <div className="text-center mb-4">
          <Image filename="sg2.png" alt="" className="w-48 mx-auto" />
          <h4>ラック＆ピニオン式</h4>
        </div>
      </div>
    </div>
  </Layout>
)
